import React from 'react'
import { graphql } from "gatsby"
import Page from '../components/Pages/Page';
import Other from '../components/Pages/Other';


function OtherTemplate(props) {
    
    return (

        <Page {...props} PageComponent={ Other } />
    )

}

export const queryPort = graphql`
fragment otherFields on NewSites {
    ...globalFields
    pages {
        type
        id
        aUri
        content {
            h1
            subTitle
            text
            image
            seoTitle
            seoDescription
        }
    }
}
query otherQuery($subDomain: String) {
    newSites(subDomain: {eq: $subDomain}) {
        ...otherFields
    }
}`

export default OtherTemplate


