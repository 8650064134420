import React from 'react'
import { Container} from "react-bootstrap";
import { TextBlock } from "../Core";
import Seo from '../seo'
import { renderSingleImage } from '../../utils/images';
import Breadcrumbs from "../Blocks/Breadcrumbs"


function Other(props) {

const content = props.content.text
  .replaceAll('{mentionsLegalesUrl}', props.global.defaults.data.terms)
  .replaceAll('{proprieteUrl}', props.global.defaults.data.data)
  .replaceAll('{website}', props.global.defaults.data.shortWebsite)
  .replaceAll('{websiteUrl}', props.global.defaults.data.longWebsite)
  .replaceAll('{hosting}', props.global.defaults.data.hosting)
  .replaceAll('{email}', props.global.contact.email)
  .replaceAll('{telephone}', props.global.contact.telephone)
  .replaceAll('{date}', props.global.defaults.data.date)
  .replaceAll('{address}', props.global.contact.address)
  .replaceAll('{registration}', props.global.contact.registration)
  .replaceAll('{contact}', props.global.contact.name)
  .replaceAll('{businessType}', props.global.contact.businessType)
  .replaceAll('{businessName}', props.global.contact.businessName)

    return (
      <>
      <Seo
          title={props.content.seoTitle || props.content.h1}
          description={props.content.seoDescription}
          metas={props.global.defaults.metas}
      />
    <Breadcrumbs home={props.global.defaults.lang.home} contentH1={props.content.h1}/>
        <Container style={{maxWidth: 1280, minHeight: 500}}>
        <div id='h1p'>
          <h1 style={{textAlign: 'center'}}>{props.content.h1}</h1>
          {props.content.subTitle ? <p style={{textAlign: 'center'}}>{props.content.subTitle || ''}</p> : null}
        </div>
        <div>
        {props.content.image ? <div id='floatedRight' data-aos="fade-left">
        {renderSingleImage(props.localImages, props.content.image)}
          </div>
           : null}

        <TextBlock>
          <div dangerouslySetInnerHTML={{ __html: content}} />

        </TextBlock>

        
        </div>
        </Container> 
        </>
    )
}

export default Other